import VuexPersistence from 'vuex-persist'

const vuexSessionStorage = new VuexPersistence({
  storage: window.sessionStorage,
  reducer (state) {
    return ({
      auth: state.auth
    })
  }
})

export default vuexSessionStorage.plugin
