<template>
  <div>
    <h2 class="text-h5">Event Gallery</h2>
    <hr />
    <v-container fluid class="ma-0 pa-0">
      <v-row v-if="loading">
        <v-col xs="12" sm="12" md="4" v-for="n in 3" :key="n">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col xs="12" sm="12" md="4" v-for="event in events" :key="event">
          <card :event-id="event" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'

import Card from './Card'

export default {
  name: 'EventGallery',
  components: { Card },

  data () {
    return ({
      events: [],
      loading: false
    })
  },

  methods: {
    getEvents () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('/events').orderBy('galleryPrefix').get()
          .then(querySnapshot => {
            var resolveValues = querySnapshot.docs.map(doc => doc.id)
            resolve(resolveValues)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      this.$data.events = await this.getEvents()
    } catch (e) {
      console.error('[event-gallery-homepage]', e)
    }
    this.$data.loading = false
  }
}
</script>
