<template>
  <v-sheet elevation="2" class="ma-0 pa-0">
    <v-container fluid class="fill-height ma-0 pa-0">
      <v-row align="stretch" no-gutters>
        <v-col cols="3">
          <v-sheet
            color="primary"
            class="text-center ma-0 pa-2 white--text fill-height d-flex flex-column align-center justify-center"
          >
            <div class="text-uppercase text-body-2" v-text="displayDay" />
            <div class="text-h4" v-text="event.startTime.getDate()" />
            <div
              class="text-uppercase"
              v-text="displayMonthYear"
            />
          </v-sheet>
        </v-col>
        <v-col class="pa-2">
          <div class="text-overline" v-text="eventStartFromNow" />
          <div class="text-h5" v-text="event.title" />
          <div class="text--secondary text-subtitle-2">
            <span v-text="completeStartTime" />
            <span v-if="event.endTime">&mdash; {{ completeEndTime }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import moment from '@/plugins/moment'

export default {
  name: 'EventCard',

  props: {
    event: {
      required: true,
      type: Object
    }
  },

  computed: {
    displayDay () { return moment(this.event.startTime).format('dddd') },
    displayMonthYear () { return moment(this.event.startTime).format('MMM YY') },

    eventStartFromNow () { return moment(this.event.startTime).fromNow() },
    completeStartTime () { return !this.event.dateOnly ? moment(this.event.startTime).format('DD MMMM YYYY, HH:mm') : moment(this.event.startTime).format('DD MMMM YYYY') },
    completeEndTime () {
      if (!this.event.endTime) return null
      if (this.event.dateOnly) {
        return moment(this.event.startTime).format('DD MMMM YYYY')
      } else {
        return moment(this.event.startTime).format('DD MMMM YYYY, HH:mm')
      }
    }
  }
}
</script>
