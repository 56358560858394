<template>
  <v-card>
    <div class="d-flex">
      <v-img style="margin-right: 1px" height="175px" width="50%" :src="thumbnails ? thumbnails[0] : img[0]" />
      <v-img style="margin-left: 1px" height="175px" width="50%" :src="thumbnails ? thumbnails[1] : img[1]" />
    </div>

    <v-card-title v-text="event.title" />
    <v-card-subtitle v-text="event.venue || ''" />
    <v-card-text v-text="formattedDate" />

    <v-card-actions class="d-flex justify-end">
      <v-btn text to="/galeri">Galeri Selengkapnya<v-icon right>mdi-chevron-right</v-icon></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from '@/plugins/moment'
import firebase from '@/plugins/firebase'

const STORAGE = firebase.storage().ref()

export default {
  name: 'GalleryCard',

  props: {
    'event-id': {
      type: String,
      required: true
    },
    thumbnails: Array
  },

  computed: {
    formattedDate () {
      if (!this.$data.event.startTime) return undefined
      return moment(this.$data.event.startTime.toDate()).format('D MMMM YY')
    }
  },

  data () {
    return ({
      loading: false,
      event: {},
      img: []
    })
  },

  methods: {
    getEvent () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('/events').doc(this.eventId).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    },

    getPhotoRefs () {
      // return firebase.functions().httpsCallable(FUNCTION_NAME)(this.prefix)
      return new Promise((resolve, reject) => {
        // var resolution = new Array()
        // var listOptions = {}

        // if (this.maxResults) { listOptions.maxResults = this.maxResults }
        // if (this.pageToken) { listOptions.pageToken = this.pageToken }

        STORAGE.child(`${this.$data.event.galleryPrefix.replace(/\/$/, '')}/thumbnails`).list({ maxResults: 2 })
          .then(results => { resolve(results.items) })
          .catch(e => {
            console.error('[getPhotoRef]', e)
            reject(e)
          })
      })
    },

    async getPhotoURLs (refs) {
      var urls = []
      refs.forEach(async ref => {
        urls.push(await ref.getDownloadURL())
      })
      return urls
    }
  },

  async mounted () {
    console.debug('[event-gallery] Checking')
    this.$data.loading = true
    try {
      this.$data.event = await this.getEvent()
      this.$data.img = await this.getPhotoURLs(await this.getPhotoRefs())
      // console.log(this.$data.img)
    } catch (e) {
      this.$store.commit('toast/setError', 'Gagal mengambil galeri')
    }

    this.$data.loading = false
  }
}
</script>
