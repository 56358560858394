export default ({
  namespaced: true,
  state: {
    navbarIsOpen: false
  },
  getters: {
    navbarIsOpen: (state) => state.navbarIsOpen
  },
  mutations: {
    setNavbarState (state, v) {
      state.navbarIsOpen = v
    },
    toggleNavbarState (state) {
      state.navbarIsOpen = !state.navbarIsOpen
    }
  },
  actions: {
  }
})
