import firebase from '@/plugins/firebase'

function getFirebaseUser () {
  return new Promise((resolve, reject) => {
    var timeout = window.setTimeout(function () {
      reject(new Error('Timed out'))
    }, 5000)
    var unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      clearTimeout(timeout)
      resolve(user)
    })
  })
}

async function isAdmin () {
  var user = await getFirebaseUser()
  return new Promise((resolve, reject) => {
    if (!user) resolve(false)

    user.getIdTokenResult().then(idTokenResult => {
      resolve(idTokenResult.claims.admin)
    }).catch(e => { reject(e) })
  })
}

export default [
  {
    path: '/admin',
    name: 'AdminView',
    component: () => import('../views/Admin/Admin.vue'),
    async beforeEnter (to, from, next) { if (await isAdmin()) { next() } else { next(from.path) } }
  },
  {
    path: '/admin/articles',
    name: 'ArticleAdmin',
    component: () => import('../views/Admin/Article/ArticleList.vue'),
    async beforeEnter (to, from, next) { if (await isAdmin()) { next() } else { next(from.path) } }
  }
]
