<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="menuScope">
      <v-btn fab text color="white" v-on="menuScope.on">
        <v-avatar size="36">
          <v-img :src="user.photoURL" v-if="user.photoURL" />
          <span v-text="initials" v-else />
        </v-avatar>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item no-action two-line>
        <v-list-item-content>
          <v-list-item-title v-text="user.displayName" />
          <v-list-item-subtitle v-text="user.email" />
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip bottom>
            <template v-slot:activator="logoutTooltipScope">
              <v-btn @click="logout" v-on="logoutTooltipScope.on" icon><v-icon>mdi-logout</v-icon></v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
      <v-list-item to="/akun">
        <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
        <v-list-item-title>Profil Saya</v-list-item-title>
      </v-list-item>
      <!-- <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-icon><v-icon>mdi-feather</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Layanan</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item v-for="service in services" :key="service.name">
          <v-list-item-content>
            <v-list-item-title>{{ service.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group> -->
    </v-list>
  </v-menu>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'UserProfilePanel',

  components: {},

  data () {
    return ({
      services: [
        { name: 'Formulir Baptisan', url: '/layanan/surat-baptis' },
        { name: 'Formulir Penyerahan Anak', url: '/layanan/surat-penyerahan' },
        { name: 'Formulir Pernikahan', url: '/layanan/nikah' }
      ]
    })
  },

  computed: {
    user () { return this.$store.getters['auth/user'] },
    initials () {
      var names = this.user === null ? null : this.user.displayName.split(' ')
      return `${names[0].substring(0, 1)}${names[names.length - 1].substring(0, 1)}`
    },
    userLoading () { return this.$store.getters['auth/loading'] },
    userClaims () {
      return (
        this.$store.getters['auth/userToken'] ? this.$store.getters['auth/userToken'].claims : null
      )
    },

    userIsAdmin () {
      return this.userClaims && this.userClaims.admin
    }
  },

  methods: {
    logout () {
      firebase.auth().signOut().then(_ => {
        this.$router.push('/logout')
      })
    }
  }
}
</script>
