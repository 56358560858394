import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import adminRoutes from './admin-routes'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/profil',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About/About.vue'),
    redirect: '/profil/sejarah',
    children: [
      {
        path: 'sejarah/',
        name: 'Sejarah',
        component: () => import('../views/About/History.vue')
      },
      {
        path: 'struktur-organisasi/',
        name: 'StrukturOrganisasi',
        component: () => import('../views/About/OrgChart.vue')
      },
      {
        path: 'pengakuan-iman/',
        name: 'PengakuanIman',
        component: () => import('../views/About/Creed.vue')
      }
    ]
  },
  {
    path: '/berita',
    name: 'NewsList',
    component: () => import('../views/News/NewsList.vue')
  },
  {
    path: '/berita/:id',
    name: 'NewsId',
    component: () => import('../views/News/NewsView.vue'),
    props: true
  },
  {
    path: '/acara',
    name: 'EventList',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/galeri',
    component: () => import('../views/Gallery/Gallery.vue')
  },
  {
    path: '/e-layanan',
    component: () => import('../views/ELayanan')
  },
  {
    path: '/logout',
    component: () => import('../views/Logout')
  },
  {
    path: '/data-jemaat',
    component: () => import('../views/DataJemaat')
  },
  // {
  //   path: '/data-setoran',
  //   component: () => import('../views/DataSetoran')
  // },
  {
    path: '/akun',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/privasi',
    component: () => import('../views/PrivacyPolicy')
  },
  {
    path: '/pendataan/aset',
    component: () => import('../views/Pendataan/Aset/AsetTahunan')
  },
  // {
  //   path: '/data-pengeluaran',
  //   component: () => import('../views/DataPengeluaran')
  // },
  // {
  //   path: '/arsip-surat',
  //   component: () => import('../views/ArsipSurat')
  // },
  // {
  //   path: '/layanan/surat-baptis',
  //   component: () => import('../views/Layanan/Baptisan')
  // },
  // {
  //   path: '/layanan/nikah',
  //   component: () => import('../views/Layanan/Pernikahan')
  // },
  // {
  //   path: '/layanan/surat-penyerahan',
  //   component: () => import('../views/Layanan/Penyerahan')
  // },
  ...adminRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
