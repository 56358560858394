import firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/auth'
import '@firebase/functions'
import '@firebase/storage'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  authDomain: 'www.gkbi.or.id',
  databaseURL: 'https://gkbi-prime.firebaseio.com',
  projectId: 'gkbi-prime',
  storageBucket: process.env.VUE_APP_GCS_BUCKET_NAME,
  messagingSenderId: '1055559131042',
  appId: '1:1055559131042:web:aac1cc8bf943e4039f5cfb'
}

firebase.initializeApp(firebaseConfig)

firebase.firestore().enablePersistence()
  .catch(e => {
    if (e.code === 'failed-precondition') {
      // console.error('Something went wrong')
    } else if (e.code === 'unimplemented') {
      // console.error('Something went wrong)
    }
  })

export default firebase
