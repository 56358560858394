<template>
  <v-footer color="primary" dark class="white--text">
    <v-container>
      <div :class="{ 'flex-column': !isOnPC}" class="d-flex align-top justify-space-between">
        <div id="mainText">
          <h6 class="text-h6">Tentang GKBI</h6>
          <ul>
            <li><router-link to="/profil/sejarah">Sejarah</router-link></li>
            <li><router-link to="/profil/struktur-organisasi">Struktur Organisasi</router-link></li>
            <li><router-link to="/profil/pengakuan-iman">Pengakuan Iman</router-link></li>
            <li><router-link to="/privasi">Kebijakan Privasi</router-link></li>
          </ul>
        </div>
        <div :class="!isOnPC ? 'mt-4' : null" id="contacts">
          <h6 class="text-h6">Kontak Kami</h6>
          <p class="ma-0"><span class="font-weight-bold">Kantor Sinode</span> <span><a href="https://goo.gl/maps/BUzNQkC15fh29T8x9">Jl. Tonsawang – Karombasan Ling. II Manado</a></span></p>
          <p class="ma-0"><span class="font-weight-bold">E-mail</span> <span><a href="mailto:bpsgkbi@gmail.com">bpsgkbi@gmail.com</a></span></p>
          <p>
            <span class="font-weight-bold">Media Sosial</span>
            <v-btn icon href="https://www.youtube.com/channel/UCTOR3sGfWbKqXjUUQqCBl3A"><v-icon>mdi-youtube</v-icon></v-btn>
            <v-btn icon href="https://wa.me/6289660535595"><v-icon>mdi-whatsapp</v-icon></v-btn>
          </p>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: white !important;
}

a:hover {
  text-decoration: white underline;
}

</style>
