<template>
  <v-app>
    <sidebar v-if="!isOnPC" />
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click="sidebarOpen = !sidebarOpen" v-if="!isOnPC"><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon>
      <v-spacer v-if="!isOnPC" />

      <div class="d-flex">
        <v-img
          :width="isOnPC ? 72 : 64"
          src="@/assets/logo.png"
          class="mt-5"
        />
        <div v-if="isOnPC" class="d-flex align-center ml-6">
          <v-btn text to="/">Beranda</v-btn>
          <v-btn text to="/berita">Berita</v-btn>
          <v-btn text to="/galeri">Galeri</v-btn>
          <v-btn text to="/acara">Jadwal Acara</v-btn>
          <menu-button
            :items="[
              { url: '/profil/sejarah', text: 'Sejarah' },
              { url: '/profil/struktur-organisasi', text: 'Struktur Organisasi' },
              { url: '/profil/pengakuan-iman', text: 'Pengakuan Iman Gereja' }
            ]"
          >
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">Profil Sinode <v-icon class="ml-2">mdi-chevron-down</v-icon></v-btn>
            </template>
          </menu-button>
          <v-menu v-if="user" v-model="showMenu_data" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>Pendataan <v-icon>mdi-chevron-down</v-icon></v-btn>
            </template>
            <v-list nav>
              <v-list-item to="/data-jemaat">
                <v-list-item-content>
                  <v-list-item-title>Data Jemaat</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/pendataan/aset">
                <v-list-item-content>
                  <v-list-item-title>Aset Sinode</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- v-list-item to="/data-setoran">
                <v-list-item-content>
                  <v-list-item-title>Data Setoran ke Sinode</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/data-pengeluaran" v-if="userIsAdmin || (userClaims && userClaims.bps)">
                <v-list-item-content>
                  <v-list-item-title>Data Pengeluaran Sinode</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/arsip-surat" v-if="userIsAdmin || (userClaims && userClaims.bps)">
                <v-list-item-content>
                  <v-list-item-title>Arsip Surat</v-list-item-title>
                </v-list-item-content>
              </v-list-item -->
            </v-list>
          </v-menu>
          <!-- v-menu v-if="user" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>Layanan <v-icon>mdi-chevron-down</v-icon></v-btn>
            </template>
            <v-list nav>
              <v-list-item :to="service.url" v-for="service in services" :key="service.name">
                <v-list-item-content>
                  <v-list-item-title>{{ service.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu -->
        </div>
      </div>
      <v-spacer />
      <div v-if="isOnPC" class="d-flex align-center justify-center">
        <login-component />
      </div>
      <div style="width: 36px" v-else />
    </v-app-bar>

    <v-main>
      <div v-if="!isOnPC" style="height: 18px"/>
      <router-view />
    </v-main>

    <Footer />

    <toast-notification />
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld'
// import UserProfilePanel from './components/UserPanels/UserProfilePanel'
import LoginComponent from './components/LoginDialog'
import Toast from './components/ToastNotification'
import firebase from '@/plugins/firebase'
import SidebarVue from './components/AppBar/Sidebar.vue'
import NavButtonWithMenuVue from './components/AppBar/NavButtonWithMenu.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',

  components: {
    // 'user-profile': UserProfilePanel
    'login-component': LoginComponent,
    'toast-notification': Toast,
    sidebar: SidebarVue,
    Footer,
    'menu-button': NavButtonWithMenuVue
  },

  data: () => ({
    showMenu_data: false,

    services: [
      { name: 'Formulir Baptisan', url: '/layanan/surat-baptis' },
      { name: 'Formulir Penyerahan Anak', url: '/layanan/surat-penyerahan' },
      { name: 'Formulir Pernikahan', url: '/layanan/nikah' }
    ]
    //
  }),

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    sidebarOpen: {
      get () { return this.$store.getters['sidebar/navbarIsOpen'] },
      set (v) { return this.$store.commit('sidebar/setNavbarState', v) }
    },

    user () {
      return this.$store.getters['auth/user']
    },

    userClaims () {
      return this.$store.getters['auth/userToken'] ? this.$store.getters['auth/userToken'].claims : null
    },

    userIsAdmin () {
      return this.userClaims ? this.userClaims.admin : false
    }
  },

  mounted () {
    this.listenForAuthState()
    this.handleAuthRedirect()
  },

  methods: {
    listenForAuthState () {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          this.$store.commit('auth/setUser', user)
          firebase.auth().currentUser.getIdTokenResult()
            .then(idTokenResult => {
              this.$store.commit('auth/setToken', idTokenResult)
            })
        } else {
          this.$store.commit('auth/destroyUser')
          this.$store.commit('auth/destroyCredentials')
        }
        this.$store.commit('auth/changeLoadingState', false)
      })
    },
    handleAuthRedirect () {
      firebase.auth().getRedirectResult().then(result => {
        if (result.credential) this.$store.commit('auth/setCredentials', result.credential)
      })
    }
  }
}
</script>
