<template>
  <v-menu v-model="showMenu" bottom>
    <template v-slot:activator="slotProps">
      <slot name="activator" v-bind="slotProps" />
      <!-- <v-btn v-on="slotProps.on">Test</v-btn> -->
    </template>
    <v-list dense>
      <v-list-item v-for="item in items" :key="JSON.stringify(item)" :to="item.url">
        <v-list-item-icon v-if="item.icon"><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'NavButtonWithMenu',
  props: {
    items: {
      required: true,
      type: Array
    }
  },

  data () {
    return ({
      showMenu: false
    })
  }
}
</script>
