<template>
  <div style="margin-top: -1.5em">
    <v-img
      :src="isOnPC ? coverPictures.desktop : coverPictures.mobile"
      class="align-center"
    >
      <v-row
        align="center"
        justify="center"
        style="width: 100%"
        :style="{ bottom: isOnPC ? '22%' : '10%'}"
        class="ma-0 pa-0"
      >
        <div style="text-shadow: 2px 2px 5px black, 2px 2px 10px black" class="text-center white--text">
          <!-- <v-btn to="/e-layanan" :small="!isOnPC" color="blue" dark>Pelajari Selengkapnya</v-btn> -->
          <p :class="isOnPC ? 'text-h4' : 'text-h6'" class="ma-0 pa-0 font-weight-light">Situs Resmi</p>
          <h1 :class="isOnPC ? 'text-h2' : 'text-h5'" class="font-weight-normal">Gereja Kristen Bahtera Injil</h1>
        </div>
      </v-row>
    </v-img>
    <v-container>
      <selected-events />
      <latest-news />
      <home-gallery />
    </v-container>
  </div>
</template>

<script>
import LatestNewsVue from '@/components/HomePage/LatestNews.vue'
import SelectedEventsVue from '@/components/HomePage/SelectedEvents.vue'
import HomeGallery from '@/components/HomePage/EventGallery/Gallery.vue'
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
    'latest-news': LatestNewsVue,
    'selected-events': SelectedEventsVue,
    'home-gallery': HomeGallery
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  data () {
    return ({
      coverPictures: {
        mobile: 'https://storage.googleapis.com/gkbi-prime.appspot.com/front-page/dove-3426187_1920.jpg',
        desktop: 'https://storage.googleapis.com/gkbi-prime.appspot.com/front-page/dove-3426187_1920-1600x900.jpg'
      }
    })
  }
}
</script>
