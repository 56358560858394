<template>
  <div>
    <user-panel v-if="userLoggedIn" />
    <v-progress-circular color="accent" v-else-if="userLoading" indeterminate/>
    <v-dialog v-else v-model="dialogIsOpen" :fullscreen="!isOnPC" persistent>
      <template v-slot:activator="dialogActivator">
        <slot name="activator" v-bind="dialogActivator">
          <v-btn
            color="accent"
            v-on="dialogActivator.on"
            :icon="!isOnPC"
          >
            <v-icon>mdi-login</v-icon>
            <span v-if="isOnPC" class="ml-2">Login</span>
          </v-btn>
        </slot>
      </template>
      <v-sheet class="fill-height">
        <v-toolbar dense flat>
          <v-btn @click="dialogIsOpen = false" icon>
            <v-icon v-if="!isOnPC">mdi-arrow-left</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container class="text-center">
          <v-row><v-col :cols="12">
            <h3 class="display-2">Login</h3>
          </v-col></v-row>
          <v-row justify="center">
            <v-col :cols="6" xs="12" sm="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="doLogin('GoogleAuthProvider')" :class="{ 'my-2': !isOnPC }" :x-large="!isOnPC" large width="150" class="mx-2" v-on="on" dark color="#DB4437"><v-icon class="mr-3">mdi-google</v-icon> Google</v-btn>
                </template>
                <span>Lanjut dengan Google</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="doLogin('FacebookAuthProvider')" :class="{ 'my-2': !isOnPC }" :x-large="!isOnPC" large width="150" class="mx-2" v-on="on" dark color="#3b5998"><v-icon class="mr-3">mdi-facebook</v-icon> Facebook</v-btn>
                </template>
                <span>Lanjut dengan Facebook</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col :cols="12" xs="12" sm="12">
              <small>
                Anda bisa login menggunakan akun Facebook atau Google anda. <br />
                Kami <em>tidak</em> menyimpan e-mail, username, ataupun kata sandi Google/Facebook. <br />
                Kami tidak akan mem-posting apapun ke media sosial anda tanpa persetujuan dari anda. Untuk info lebih lanjut lihat laman <router-link to="/privasi">Kebijakan Privasi</router-link>.
              </small>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
// import firebase from '../plugins/firebase'
import UserProfilePanel from './AppBar/UserPanels/UserProfilePanel'
import firebase from '@/plugins/firebase'

export default {
  name: 'LoginDialog',

  components: {
    'user-panel': UserProfilePanel
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    userLoading () { return this.$store.getters['auth/userLoading'] },
    userLoggedIn () { return !!this.$store.getters['auth/user'] }
  },

  data () {
    return ({
      dialogIsOpen: false
    })
  },

  methods: {
    doLogin (providerMethodName) {
      var provider = new firebase.auth[providerMethodName]()
      // provider.addScope('email')
      // provider.addScope('profile')

      // firebase.auth().signInWithPopup(provider).then(result => {
      //   this.$store.commit('auth/setUser', result.user)
      //   this.$store.commit('auth/setToken', result.token)
      // })
      firebase.auth().signInWithRedirect(provider)
    }
  }
}
</script>
