<template>
  <div class="mb-5" v-if="events.length">
    <div class="d-flex align-center justify-space-between">
      <h2 class="text-h4">Jadwal Event</h2>
      <v-btn color="primary" outlined class="ml-6" to="/acara">Lihat Jadwal Lengkap</v-btn>
    </div>
    <v-container>
      <v-row>
        <v-col xs="12" md="6" lg="4" v-for="event in parsedEvents" :key="event.name">
          <event-card :event="event" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import moment from 'moment'
import EventCardVue from '@/components/Event/EventCard.vue'
import firebase from '@/plugins/firebase'

export default {
  name: 'HomePageEventViewer',

  components: {
    'event-card': EventCardVue
  },

  data () {
    return ({
      events: [
        // {
        //   title: 'Contoh Acara',
        //   startTime: new Date()
        // }
      ],
      loading: false
    })
  },

  computed: {
    parsedEvents () {
      return this.$data.events.map(event => {
        event.startTime = event.startTime ? event.startTime.toDate() : null
        event.endTime = event.endTime ? event.endTime.toDate() : null

        return event
      })
    }
  },

  async mounted () {
    try { this.$data.events = await this.getSelectedEvents() } catch (e) { }
  },

  methods: {
    getSelectedEvents () {
      return new Promise((resolve, reject) => {
        var now = new Date()
        var resolution = []
        var eventCollection = firebase.firestore().collection('/events')
        var queries = [
          eventCollection.where('endTime', '>', now).get(),
          eventCollection.where('endTime', '==', null).where('startTime', '>=', now).get()
        ]

        Promise.all(queries).then(
          querySnapshots => {
            querySnapshots.forEach(querySnapshot => {
              querySnapshot.docs.forEach(doc => { resolution.push(Object.assign(doc.data(), { id: doc.id })) })
            })
            resolve(resolution)
          }
        ).catch(e => { reject(e) })
      })
    }
  }
}
</script>
