<template>
  <v-card :to="`/berita/${newsItem.url}`">
    <v-img :src="newsItem.image" :aspect-ratio="isOnPC ? 4 / 3 : 16 / 9" />
    <v-card-title>{{ newsItem.title }}</v-card-title>
    <v-card-text>{{ newsItem.summary }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NewsCard',

  props: {
    'news-item': {
      required: true
    }
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>
