var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(!_vm.isOnPC)?_c('sidebar'):_vm._e(),_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[(!_vm.isOnPC)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.sidebarOpen = !_vm.sidebarOpen}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),(!_vm.isOnPC)?_c('v-spacer'):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-img',{staticClass:"mt-5",attrs:{"width":_vm.isOnPC ? 72 : 64,"src":require("@/assets/logo.png")}}),(_vm.isOnPC)?_c('div',{staticClass:"d-flex align-center ml-6"},[_c('v-btn',{attrs:{"text":"","to":"/"}},[_vm._v("Beranda")]),_c('v-btn',{attrs:{"text":"","to":"/berita"}},[_vm._v("Berita")]),_c('v-btn',{attrs:{"text":"","to":"/galeri"}},[_vm._v("Galeri")]),_c('v-btn',{attrs:{"text":"","to":"/acara"}},[_vm._v("Jadwal Acara")]),_c('menu-button',{attrs:{"items":[
            { url: '/profil/sejarah', text: 'Sejarah' },
            { url: '/profil/struktur-organisasi', text: 'Struktur Organisasi' },
            { url: '/profil/pengakuan-iman', text: 'Pengakuan Iman Gereja' }
          ]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Profil Sinode "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,565263565)}),(_vm.user)?_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Pendataan "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,49843717),model:{value:(_vm.showMenu_data),callback:function ($$v) {_vm.showMenu_data=$$v},expression:"showMenu_data"}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{attrs:{"to":"/data-jemaat"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Data Jemaat")])],1)],1),_c('v-list-item',{attrs:{"to":"/pendataan/aset"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Aset Sinode")])],1)],1)],1)],1):_vm._e()],1):_vm._e()],1),_c('v-spacer'),(_vm.isOnPC)?_c('div',{staticClass:"d-flex align-center justify-center"},[_c('login-component')],1):_c('div',{staticStyle:{"width":"36px"}})],1),_c('v-main',[(!_vm.isOnPC)?_c('div',{staticStyle:{"height":"18px"}}):_vm._e(),_c('router-view')],1),_c('Footer'),_c('toast-notification')],1)}
var staticRenderFns = []

export { render, staticRenderFns }