import Vue from 'vue'
import Vuex from 'vuex'

import PersistAuth from './plugins/persistence'

import auth from './auth'
import toast from './toast-notification'
import sidebar from './sidebar'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth, toast, sidebar
  },
  plugins: [PersistAuth]
})
