<template>
  <v-navigation-drawer light v-model="navbarIsOpen" app>
    <v-list nav dense>
      <drawer-user-panel v-if="user" />
      <login-component v-else>
        <template v-slot:activator="{ on }" >
          <v-list-item v-on="on">
            <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Login</v-list-item-title></v-list-item-content>
          </v-list-item>
        </template>
      </login-component>
      <v-divider />
      <v-list-item to="/">
        <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Beranda</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/berita">
        <v-list-item-icon><v-icon>mdi-newspaper</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Berita</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/galeri">
        <v-list-item-icon><v-icon>mdi-image-multiple</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Galeri</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/acara">
        <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Acara</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item to="/profil">
        <v-list-item-icon><v-icon>mdi-information-variant</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profil Sinode</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-icon><v-icon>mdi-information-variant</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profil Sinode</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item to="/profil/sejarah">
          <v-list-item-content>
            <v-list-item-title>Sejarah</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/profil/struktur-organisasi">
          <v-list-item-content>
            <v-list-item-title>Struktur Organisasi</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/profil/pengakuan-iman">
          <v-list-item-content>
            <v-list-item-title>Pengakuan Iman Gereja</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group v-if="user" no-action>
        <template v-slot:activator>
          <v-list-item-icon><v-icon>mdi-table</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pendataan</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item to="/data-jemaat">
          <v-list-item-content>
            <v-list-item-title>Data Jemaat</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/pendataan/aset">
          <v-list-item-content>
            <v-list-item-title>Aset Sinode</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item to="/data-setoran">
          <v-list-item-content>
            <v-list-item-title>Data Setoran ke Sinode</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/data-pengeluaran" v-if="userClaims && (userClaims.admin || userClaims.bps)">
          <v-list-item-content>
            <v-list-item-title>Data Pengeluaran Sinode</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/arsip-surat" v-if="userClaims && (userClaims.admin || userClaims.bps)">
          <v-list-item-content>
            <v-list-item-title>Arsip Surat</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list-group>
      <!-- <v-list-group v-if="user" no-action>
        <template v-slot:activator>
          <v-list-item-icon><v-icon>mdi-feather</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Layanan</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item :to="service.url" v-for="service in services" :key="service.name">
          <v-list-item-content>
            <v-list-item-title>{{ service.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import LoginDialogVue from '../LoginDialog.vue'
import UserProfilePanelDrawerVue from './UserPanels/UserProfilePanelDrawer.vue'

export default {
  name: 'Navbar',

  components: {
    'drawer-user-panel': UserProfilePanelDrawerVue,
    'login-component': LoginDialogVue
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    navbarIsOpen: {
      get () { return this.$store.getters['sidebar/navbarIsOpen'] },
      set (v) { this.$store.commit('sidebar/setNavbarState', v) }
    },
    userClaims () {
      return (
        this.$store.getters['auth/userToken'] ? this.$store.getters['auth/userToken'].claims : null
      )
    },

    userIsAdmin () {
      return this.userClaims && this.userClaims.admin
    }
  },

  data () {
    return ({
      services: [
        { name: 'Formulir Baptisan', url: '/layanan/surat-baptis' },
        { name: 'Formulir Penyerahan Anak', url: '/layanan/surat-penyerahan' },
        { name: 'Formulir Pernikahan', url: '/layanan/nikah' }
      ]
    })
  }
}
</script>
