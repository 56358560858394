<template>
  <v-list-item two-line>
    <v-list-item-avatar>
      <v-avatar size="36">
        <v-img :src="user.photoURL" v-if="user.photoURL" />
        <span v-text="initials" v-else />
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ user.displayName }}</v-list-item-title>
      <v-list-item-subtitle>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="menuScope">
            <v-btn small text v-on="menuScope.on">Akun Saya <v-icon small>mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-list dense>
            <v-list-item to="/akun">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profil Saya</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn @click="logout" icon><v-icon>mdi-logout</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'UserProfilePanel',

  components: {},

  data () {
    return ({

    })
  },

  computed: {
    user () { return this.$store.getters['auth/user'] },
    initials () {
      var names = this.user === null ? null : this.user.displayName.split(' ')
      return `${names[0].substring(0, 1)}${names[names.length - 1].substring(0, 1)}`
    },
    userLoading () { return this.$store.getters['auth/loading'] }
  },

  methods: {
    logout () {
      firebase.auth().signOut().then(_ => {
        this.$router.push('/logout')
      })
    }
  }
}
</script>
